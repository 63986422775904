/* height of the container */
.leaflet-container {
  height: 100%;
  width: 90%;
  border-radius: 2rem;
}

.cluster-icon {
  background-color: #333;
  height: 2em;
  width: 2em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  box-shadow: 0 0 0px 5px #fff;
}

.spinner {
  height: 60px;
  width: 60px;
  margin: auto;
  display: flex;
  position: absolute;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, .15);
  border-right: 6px solid rgba(0, 174, 239, .15);
  border-bottom: 6px solid rgba(0, 174, 239, .15);
  border-top: 6px solid rgba(0, 174, 239, .8);
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

#overlay {
  position:absolute;
  top:0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgb(0,0,0,0.2);
}



.text-center {
  width: 100%;
  position: relative;
  height: 100%;
}

.spinner-border {
  z-index: 10000;
  display: block;
  position: fixed;
  top: calc(50% - (58px / 2));  
  right: calc(50% - (58px / 2));
  color: red;
}

#backdrop {
position:absolute;
top:0;
width: 100vw;
height: 100vh;
z-index: 999;
background-color: rgb(0,0,0,0.2);
}
